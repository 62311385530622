<template>
    <div>
        <h3>{{$t(`companyOwnersForm.${id ? 'edit' : 'create'}`)}}</h3>
        <b-form @submit.prevent="onSubmit">
            <h4>{{ $t('companyOwnersForm.personalHeading') }}</h4>
            <div class="row">
                <div class="col-12 col-md-2">
                    <form-input
                        :id="'companyOwner.titleBefore'"
                        :label="$t('basicData.titleBefore')"
                        v-model.trim="titleBefore"
                    />
                </div>
                <div class="col-12 col-md-4">
                    <form-input
                        :id="'companyOwner.name'"
                        :label="$t('basicData.name')"
                        v-model.trim="name"
                        :validation="$v.name"
                    />
                </div>
                <div class="col-12 col-md-4">
                    <form-input
                        :id="'companyOwner.surname'"
                        :label="$t('basicData.surname')"
                        v-model.trim="surname"
                        :validation="$v.surname"
                    />
                </div>
                <div class="col-12 col-md-2">
                    <form-input
                        :id="'companyOwner.titleAfter'"
                        :label="$t('basicData.titleAfter')"
                        v-model.trim="titleAfter"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-4">
                    <form-input
                        :id="'companyOwner.rc'"
                        :disabled="noRc"
                        :label="$t('basicData.rc')"
                        v-model="rc"
                        :validation="$v.rc"
                        :formatter="birthNumberFormatter"
                        :validation-messages="{ custom: (`${rc}`.length === 11) ? $t('validation.invalidRCControlNumber') : $t('validation.invalidRCLength') }"
                        @input="onBirthNumberInput"
                    />

                    <form-input-checkbox
                        :id="'companyOwner.noRc'"
                        :label="$t('basicData.noRc')"
                        v-model="noRc"
                    />
                </div>
                <div class="col-12 col-md-4">
                    <form-input-date-picker
                        :id="'companyOwner.birthDate'"
                        :label="$t('basicData.birthDate')"
                        v-model="birthDate"
                        :hint="birthDateHint"
                        :max-date="new Date()"
                        :locale="datePickerLang"
                        :validation="$v.birthDate"
                    >
                        <template #input-text>
                            <a href="javascript:void(0)"
                                @click.prevent="onSetBirthDate"
                            >
                                <small>{{$t('basicData.setBirthDateFromRC')}}</small>
                            </a>
                        </template>
                    </form-input-date-picker>
                </div>

                <div class="col-12 col-md-4">
                    <form-input
                        :id="'companyOwner.birthCity'"
                        :label="$t('companyOwnersForm.birthCity')"
                        v-model="birthCity"
                        :validation="$v.birthCity"
                    />
                </div>

                <div class="col-12 col-md-4">
                    <form-input-select
                        :id="'companyOwner.citizenship'"
                        :label="$t('companyOwnersForm.citizenship')"
                        v-model="citizenship"
                        :options="countries"
                        :validation="$v.citizenship"
                    />
                </div>

                <div class="col-12 col-md-4">
                    <form-input-radio-group
                        :id="'companyOwner.sex'"
                        :label="$t('companyOwnersForm.sex')"
                        v-model="sex"
                        :options="genders"
                        :validation="$v.sex"
                        :stacked="false"
                    />
                </div>
            </div>

            <form-input
                :id="'companyOwner.countryOrigin'"
                :label="$t('basicData.countryOrigin')"
                v-model="countryOrigin"
                :validation="$v.countryOrigin"
            />
            <i18n path="basicData.countryOriginInfoText.other.text"
                class="alert alert-primary small"
                tag="p"
            >
                <strong>{{ $t('basicData.countryOriginInfoText.other.text1Strong') }}</strong>
                <strong>{{ $t('basicData.countryOriginInfoText.other.text2Strong') }}</strong>
                <strong>{{ $t('basicData.countryOriginInfoText.other.text3Strong') }}</strong>
                <strong>{{ $t('basicData.countryOriginInfoText.other.text4Strong') }}</strong>
            </i18n>

            <h4 class="mt-5">{{ $t('companyOwnersForm.addressHeading') }}</h4>
            <div class="row">
                <div class="col-12 col-md-4">
                    <form-input
                        :id="'companyOwner.addressStreet'"
                        :label="$t('basicData.addressStreet')"
                        v-model="addressStreet"
                        :validation="$v.addressStreet"
                    />
                </div>
                <div class="col-12 col-md-4">
                    <form-input
                        :id="'companyOwner.addressCity'"
                        :label="$t('basicData.addressCity')"
                        v-model="addressCity"
                        :validation="$v.addressCity"
                    />
                </div>
                <div class="col-12 col-md-4">
                    <form-input
                        :id="'companyOwner.addressZip'"
                        :label="$t('basicData.addressZip')"
                        v-model="addressZip"
                        :validation="$v.addressZip"
                        :validation-messages="{ custom: $t('validation.invalidZipLength') }"
                    />
                </div>
            </div>

            <form-input-select
                :id="'companyOwner.addressCountry'"
                :label="$t('basicData.addressCountry')"
                v-model="addressCountry"
                :options="countries"
                :validation="$v.addressCountry"
            />

            <h4 class="mt-5">{{ $t('companyOwnersForm.contactHeading') }}</h4>
            <div class="row">
                <div class="col-12 col-md-4">
                    <form-input
                        :id="'companyOwner.phoneNumberComputed'"
                        :label="$t('basicData.phone')"
                        v-model="phoneNumberComputed"
                        :validation="$v.phone"
                        :formatter="phoneFormatter"
                        :hint="$t('basicData.desc.phoneFormatHint')"
                    />
                </div>
                <div class="col-12 col-md-4">
                    <form-input
                        :id="'companyOwner.email'"
                        :label="$t('basicData.email')"
                        type="email"
                        v-model="email"
                        :validation="$v.email"
                    />
                </div>
            </div>

            <h4 class="mt-5">{{ $t('companyOwnersForm.documentHeading') }}</h4>
            <div class="row">
                <div class="col-12 col-md-4">
                    <form-input
                        :id="'companyOwner.documentNumber'"
                        :label="$t('companyOwnersForm.documentNumber')"
                        v-model="documentNumber"
                        :validation="$v.documentNumber"
                    />
                </div>
                <div class="col-12 col-md-4">
                    <form-input-date-picker
                        :id="'companyOwner.documentValidity'"
                        :label="$t('companyOwnersForm.documentValidity')"
                        :validation="$v.documentValidity"
                        v-model="documentValidity"
                        :min-date="new Date()"
                        :locale="datePickerLang"
                    />
                </div>
                <div class="col-12 col-md-4">
                    <form-input
                        :id="'companyOwner.documentIssuedBy'"
                        :label="$t('companyOwnersForm.documentIssuedBy')"
                        v-model="documentIssuedBy"
                        :validation="$v.documentIssuedBy"
                    />
                </div>
                <div class="col-12 col-md-4">
                    <form-input
                        :id="'companyOwner.jobFunction'"
                        :label="$t('companyOwnersForm.jobFunction')"
                        v-model="jobFunction"
                        :validation="$v.jobFunction"
                    />
                </div>
                <div class="col-12 col-md-8">
                    <form-input-checkbox
                        class="mt-4 pt-2"
                        :id="'companyOwner.politician'"
                        :label="$t('companyOwnersForm.politician')"
                        v-model="politician"
                    >
                        <template>
                            {{ $t('basicData.politician') }}
                            <b-icon id="infoPopover" @click.prevent.stop="showPopover = !showPopover" icon="info-circle" class="ml-1"/>
                        </template>
                    </form-input-checkbox>

                    <b-popover
                        :show.sync="showPopover"
                        :custom-class="'popover-large'"
                        target="infoPopover"
                        :triggers="['hover', 'focus']"
                        placement="bottom"
                    >
                        {{ $t('basicData.politicianInfo') }}
                    </b-popover>
                </div>
            </div>

            <h4 class="mt-5">
            {{ $t('companyOwnersForm.uploadsHeading') }}
            </h4>

            <form-input-radio-group
                :label="$t('personalId.documentType')"
                v-model="documentType"
                :validation="$v.documentType"
                :options="primarySelectOptions"
                :stacked="false"
            />
            <document-uploader
                v-if="documentType"
                class="mb-3"
                :document-type="documentType"
                :options="primaryDocumentTypesOptions"
                :uploader-url="$api.clientSessionUploads.uploaderUrl"
                :validation="$v.filteredStoredUploads"
                :uploads="uploads"
                :removed-uploads="removedUploads"
                :filtered-stored-uploads="filteredStoredUploads"
                @uploaded="onUploadUploaded"
                @remove="onUploadRemove"
                @restore="onUploadRestore"
            />
            <form-input-radio-group
                :label="$t('personalId.secondaryDocumentType')"
                v-model="secondaryDocumentType"
                :validation="$v.secondaryDocumentType"
                :options="secondarySelectOptions"
                :stacked="false"
            />
            <document-uploader
                v-if="secondaryDocumentType"
                :document-type="secondaryDocumentType"
                :options="secondaryDocumentTypesOptions"
                :uploader-url="$api.clientSessionUploads.uploaderUrl"
                :validation="$v.filteredStoredUploads"
                :uploads="uploads"
                :removed-uploads="removedUploads"
                :filtered-stored-uploads="filteredStoredUploads"
                @uploaded="onUploadUploaded"
                @remove="onUploadRemove"
                @restore="onUploadRestore"
            />

            <div class="mt-5">
                <b-btn
                    type="submit"
                    variant="primary"
                    class="mr-4 mb-2"
                >
                    {{$t('app.submit')}}
                </b-btn>

                <b-btn
                    variant="light"
                    @click.prevent="onCancel"
                    class="mb-2"
                >
                    {{$t('app.cancel')}}
                </b-btn>
            </div>

        </b-form>
    </div>
</template>

<script>
import { required, email, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { mod11_10 as mod1110 } from 'cdigit'
import * as objectHash from 'object-hash'
import { parsePhoneNumberFromString, parseIncompletePhoneNumber } from 'libphonenumber-js'
import DocumentUploader from './DocumentUploader.vue'

export default {
    name: 'CompanyPersonForm',
    components: {
        DocumentUploader
    },
    props: {
        defaults: {
            type: Object,
            required: false,
            default: () => ({})
        },
        datePickerLang: {
            type: String,
            required: false,
            default: 'cz'
        },
        showValidation: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            ivanId: this.defaults.ivanId || null,
            id: this.defaults._id || null,
            titleBefore: this.defaults.titleBefore || null,
            name: this.defaults.name || null,
            surname: this.defaults.surname || null,
            titleAfter: this.defaults.titleAfter || null,
            rc: this.defaults.rc || null,
            noRc: this.defaults.noRc || false,
            birthDate: this.defaults.birthDate || null,
            birthCity: this.defaults.birthCity || null,
            jobFunction: this.defaults.jobFunction || null,
            phone: this.defaults.phone || null,
            email: this.defaults.email || null,
            addressStreet: this.defaults.addressStreet || null,
            addressCity: this.defaults.addressCity || null,
            addressZip: this.defaults.addressZip || null,
            citizenship: this.defaults.citizenship || null,
            sex: this.defaults.sex || null,
            documentNumber: this.defaults.documentNumber || null,
            documentValidity: this.defaults.documentValidity || null,
            documentIssuedBy: this.defaults.documentIssuedBy || null,
            politician: this.defaults.politician || false,
            addressCountry: this.defaults.addressCountry || null,
            documentType: this.defaults.documentType || null,
            secondaryDocumentType: this.defaults.secondaryDocumentType || null,
            uploads: [],
            loadingUploads: false,
            removedUploads: [],
            countryOrigin: this.defaults.countryOrigin || null,
            showPopover: false
        }
    },
    computed: {
        ...mapGetters([
            'genders',
            'countries'
        ]),
        primarySelectOptions () {
            return this.primaryDocumentTypesOptions.map(option => ({
                value: option.value,
                text: option.text
            }))
        },
        primaryDocumentTypesOptions () {
            return [
                {
                    value: 'OP',
                    text: this.$t('personalIdTypes.OP'),
                    uploads: [
                        {
                            title: this.$t('personalId.opFrontTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'op.front'
                        },
                        {
                            title: this.$t('personalId.opBackTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'op.back'
                        }
                    ]
                },
                {
                    value: 'PAS',
                    text: this.$t('personalIdTypes.PAS'),
                    uploads: [
                        {
                            title: this.$t('personalId.pasTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'pas'
                        }
                    ]
                },
                {
                    value: 'PP',
                    text: this.$t('personalIdTypes.PP'),
                    uploads: [
                        {
                            title: this.$t('personalId.ppFrontTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'pp.front'
                        },
                        {
                            title: this.$t('personalId.ppBackTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'pp.back'
                        }
                    ]
                }
            ]
        },
        secondarySelectOptions () {
            return this.secondaryDocumentTypesOptions.map(option => ({
                value: option.value,
                text: option.text
            }))
        },
        secondaryDocumentTypesOptions () {
            return [
                {
                    value: 'OP',
                    text: this.$t('personalIdTypes.OP'),
                    uploads: [
                        {
                            title: this.$t('personalId.opFrontTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'op.front'
                        },
                        {
                            title: this.$t('personalId.opBackTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'op.back'
                        }
                    ]
                },
                {
                    value: 'PAS',
                    text: this.$t('personalIdTypes.PAS'),
                    uploads: [
                        {
                            title: this.$t('personalId.pasTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'pas'
                        }
                    ]
                },
                {
                    value: 'RP',
                    text: this.$t('personalIdTypes.RP'),
                    uploads: [
                        {
                            title: this.$t('personalId.rpTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'rp'
                        }
                    ]
                },
                {
                    value: 'PP',
                    text: this.$t('personalIdTypes.PP'),
                    uploads: [
                        {
                            title: this.$t('personalId.ppFrontTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'pp.front'
                        },
                        {
                            title: this.$t('personalId.ppBackTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'pp.back'
                        }
                    ]
                },
                {
                    value: 'ZP',
                    text: this.$t('personalIdTypes.ZP'),
                    uploads: [
                        {
                            title: this.$t('personalId.zpTitle'),
                            noDocumentsText: this.$t('personalId.noUploads'),
                            metaField: 'zp'
                        }
                    ]
                }
            ].filter(item => item.value !== this.documentType)
        },
        storedUploads: {
            get () {
                return this.$store.state.uploads.companyPerson || []
            },
            set (value) {
                this.$store.commit('setCompanyPersonUploads', [...value])
            }
        },
        filteredStoredUploads () {
            return this.storedUploads.filter(({ upload }) => !this.removedUploads.includes(upload))
        },
        minBirthDate () {
            const minDate = new Date()
            minDate.setHours(12, 0, 0, 0)
            minDate.setFullYear(minDate.getFullYear() - 18)
            return minDate
        },
        birthDateHint () {
            if (this.birthDateFromBirthNumber() instanceof Date && this.birthDate !== null) {
                const fixedDate = new Date(this.birthDate.valueOf())
                fixedDate.setHours(12, 0, 0, 0)
                if (!this.noRc && fixedDate.valueOf() !== this.birthDateFromBirthNumber().valueOf()) {
                    return `${this.$t('validation.invalidBirthDateFromRC')} ${this.birthDateFromBirthNumber().toLocaleDateString()}`
                }
            }
            return undefined
        },
        phoneNumberComputed: {
            get () {
                return this.phone
            },
            set (value) {
                const number = this.validatePhoneNumber(value)
                this.phone = (number !== null) ? number : value
            }
        },
        dataHash () {
            const { removedUploads, loadingUploads, uploads, ...rest } = JSON.parse(JSON.stringify(this.$data))
            rest.uploads = [...this.filteredStoredUploads]
            return objectHash.sha1(rest)
        }
    },
    watch: {
        defaults () {
            this.$nextTick(() => {
                this.ivanId = this.defaults.ivanId || null
                this.id = this.defaults._id || null
                this.titleBefore = this.defaults.titleBefore || null
                this.name = this.defaults.name || null
                this.surname = this.defaults.surname || null
                this.name = this.defaults.name || null
                this.titleAfter = this.defaults.titleAfter || null
                this.rc = this.defaults.rc || null
                this.noRc = this.defaults.noRc || false
                this.birthDate = this.defaults.birthDate || null
                this.birthCity = this.defaults.birthCity || null
                this.jobFunction = this.defaults.jobFunction || null
                this.phone = this.defaults.phone || null
                this.email = this.defaults.email || null
                this.addressStreet = this.defaults.addressStreet || null
                this.addressCity = this.defaults.addressCity || null
                this.addressZip = this.defaults.addressZip || null
                this.citizenship = this.defaults.citizenship || null
                this.sex = this.defaults.sex || null
                this.documentNumber = this.defaults.documentNumber || null
                this.documentValidity = this.defaults.documentValidity || null
                this.documentIssuedBy = this.defaults.documentIssuedBy || null
                this.politician = this.defaults.politician || false
                this.addressCountry = this.defaults.addressCountry || null
                this.documentType = this.defaults.documentType || null
                this.secondaryDocumentType = this.defaults.secondaryDocumentType || null
                this.storedUploads = []
                this.removedUploads = []
                this.countryOrigin = this.defaults.countryOrigin || null
                this.$nextTick(() => {
                    if (Array.isArray(this.defaults.uploads)) {
                        this.storedUploads = [...this.defaults.uploads]
                    }
                    this.loadUploads()
                })
            })
        },
        dataHash (newValue) {
            this.$store.commit('setDataHash', { name: 'companyPerson', hash: newValue })
        }
    },
    mounted () {
        if (Array.isArray(this.defaults.uploads)) {
            this.storedUploads = [...this.defaults.uploads]
        }
        this.loadUploads()
            .then(() => {
                this.$store.commit('setDataHash', { name: 'companyPersonOld', hash: ((Object.keys(this.defaults).length > 0) ? `${this.dataHash}` : null) })
                this.$store.commit('setDataHash', { name: 'companyPerson', hash: null })
                if (this.showValidation) {
                    this.$nextTick(() => {
                        this.$v.$touch()
                    })
                }
            })
    },
    methods: {
        birthNumberFormatter (value) {
            return (`${value}`.replace(/\D/ig, '').length === 11) ? `${value}`.replace(/\D/ig, '') : this.$stringFormat.birthNumber(value)
        },
        onBirthNumberInput () {
            const numericValue = `${this.rc}`.replace(/\D/ig, '')
            if (numericValue.length === 11 && mod1110.validate(numericValue)) {
                const date = new Date()
                date.setHours(12, 0, 0, 0)
                this.birthDate = date
                this.addressCountry = 'HR'
                this.$v.birthDate.$touch()
            }

            if (!this.$v.rc.$invalid && (this.birthDateFromBirthNumber() instanceof Date)) {
                this.onSetBirthDate()
            }
        },
        onSetBirthDate () {
            if (this.birthDateFromBirthNumber() instanceof Date) {
                const date = new Date(this.birthDateFromBirthNumber().valueOf())
                date.setHours(12, 0, 0, 0)
                this.birthDate = date
                this.$v.birthDate.$touch()
            }
        },
        birthDateFromBirthNumber () {
            if (this.$v.rc.$invalid) {
                return null
            }
            if (`${this.rc}`.replace(/\D/ig, '').length > 10) {
                return null
            }
            const birthNumber = `${this.rc}`
            const base = birthNumber.slice(0, 6)
            let year = parseInt(base.slice(0, 2))
            if (birthNumber.length === 11 && year < 54) {
                year += 2000
            } else {
                year += 1900
            }
            let month = parseInt(base.slice(2, 4))
            if (month > 12) {
                month -= 50
            }
            month -= 1
            const day = parseInt(base.slice(4, 6))
            const birthDate = new Date(year, month, day, 12, 0, 0, 0)
            return birthDate
        },
        phoneFormatter (number) {
            if (number) {
                return parseIncompletePhoneNumber(number)
            }
            return number
        },
        validatePhoneNumber (value) {
            const phoneNumber = parsePhoneNumberFromString(`${value}`)
            if (phoneNumber && phoneNumber.nationalNumber.length >= 9) {
                return phoneNumber.format('E.164')
            }
            return null
        },
        onCancel () {
            this.onReset()
            this.$store.commit('setDataHash', { name: 'companyPersonOld', hash: null })
            this.$store.commit('setDataHash', { name: 'companyPerson', hash: null })
            this.$emit('cancel')
        },
        onReset () {
            if (!this.id && this.storedUploads.length > 0) {
                Promise.all(this.storedUploads.map(id => this.$api.clientSessionUploads.delete(id))).catch(() => ({}))
            }
            this.id = null
            this.titleBefore = null
            this.name = null
            this.surname = null
            this.titleAfter = null
            this.name = null
            this.surname = null
            this.rc = null
            this.noRc = false
            this.birthDate = null
            this.birthCity = null
            this.jobFunction = null
            this.phone = null
            this.email = null
            this.addressStreet = null
            this.addressCity = null
            this.addressZip = null
            this.citizenship = null
            this.sex = null
            this.documentNumber = null
            this.documentValidity = null
            this.documentIssuedBy = null
            this.politician = false
            this.addressCountry = null
            this.storedUploads = []
            this.countryOrigin = null
            this.$store.commit('setDataHash', { name: 'companyPersonOld', hash: null })
            this.$store.commit('setDataHash', { name: 'companyPerson', hash: null })
        },
        async loadUploads () {
            this.loadingUploads = true
            try {
                const { data } = await this.$api.clientSessionUploads.read()
                this.uploads = data.map(item => {
                    const found = this.storedUploads.find(upload => upload.upload === item._id)
                    return {
                        ...item,
                        meta: found?.meta ?? null
                    }
                }).filter(item => item.meta)
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('errors.cannotLoadUploads'))
            } finally {
                this.$nextTick(() => {
                    this.loadingUploads = false
                })
            }
        },
        async onUploadRemove (upload) {
            if (this.id) {
                this.removedUploads.push(upload._id)
            } else {
                this.loadingUploads = true
                try {
                    await this.$api.clientSessionUploads.delete(upload._id)
                    this.storedUploads = this.storedUploads.filter(item => (item.upload !== upload._id))
                } catch (error) {
                    console.error(error)
                } finally {
                    this.loadUploads()
                }
            }
        },
        onUploadRestore (upload) {
            this.removedUploads = this.removedUploads.filter(id => id !== upload._id)
        },
        onUploadUploaded (data, type) {
            const serverData = JSON.parse(data.serverId)
            if (Array.isArray(serverData) && serverData.length > 0) {
                const tmpMap = new Map(this.storedUploads.map(item => [item.upload, item]))
                for (const data of serverData) {
                    if (!tmpMap.has(data._id)) {
                        tmpMap.set(data._id, {
                            upload: data._id,
                            meta: type
                        })
                    }
                }
                this.storedUploads = [...tmpMap.values()]
                this.$nextTick(() => {
                    this.loadUploads()
                })
            }
        },
        onSubmit () {
            this.$v.$reset()
            this.$nextTick(() => {
                if (this.$v.$invalid) {
                    this.$notify.error(this.$t('errors.someDataIsMissing'))
                    this.$v.$touch()
                } else {
                    const { loadingUploads, removedUploads, id, showPopover, ...data } = JSON.parse(JSON.stringify(this.$data))
                    data._id = id
                    data.uploads = [...this.filteredStoredUploads]
                    if (removedUploads.length > 0) {
                        Promise.all(removedUploads.map(id => this.$api.clientSessionUploads.delete(id))).catch(() => ({}))
                    }

                    const metaFields = [
                        this.primaryDocumentTypesOptions.find(item => item.value === this.documentType),
                        this.secondaryDocumentTypesOptions.find(item => item.value === this.secondaryDocumentType)
                    ].filter(item => item).map(item => (item?.uploads ?? []).map(upload => upload.metaField)).flat().filter(item => item)

                    const nonSelectedUploads = this.storedUploads.filter(item => !metaFields.includes(item.meta))

                    Promise.all(nonSelectedUploads.map(item => this.$api.clientSessionUploads.delete(item.upload))).catch(() => ({}))

                    data.uploads = data.uploads.filter(item => metaFields.includes(item.meta))

                    this.$emit('submit', data)
                }
            })
        }
    },
    validations () {
        const _this = this
        return {
            name: {
                required
            },
            surname: {
                required
            },
            addressStreet: {
                required
            },
            addressCity: {
                required
            },
            addressCountry: {
                required
            },
            addressZip: {
                required,
                custom (value) {
                    return (value && value.replace(/\s/g, '').length >= 5)
                }
            },
            rc: {
                required: requiredIf(() => {
                    return !_this.noRc
                }),
                custom (value) {
                    if (_this.noRc) {
                        return true
                    }
                    const numericValue = `${value}`.replace(/\D/ig, '')
                    if (numericValue.length === 11) {
                        return mod1110.validate(numericValue)
                    }
                    return numericValue.length >= 9
                }
            },
            birthDate: {
                required,
                custom: function (value) {
                    const today = new Date()
                    today.setHours(23, 59, 59, 0)
                    if (value instanceof Date) {
                        return (value <= today)
                    } else {
                        try {
                            const date = new Date(value)
                            return (date <= today)
                        } catch (err) {
                            return false
                        }
                    }
                }
            },
            citizenship: {
                required
            },
            documentNumber: {
                required
            },
            documentIssuedBy: {
                required
            },
            documentValidity: {
                required,
                custom: function (value) {
                    const today = new Date()
                    today.setHours(0, 0, 0, 0)
                    if (value instanceof Date) {
                        return (value >= today)
                    } else {
                        try {
                            const date = new Date(value)
                            return (date >= today)
                        } catch (err) {
                            return false
                        }
                    }
                }
            },
            documentType: {
                required
            },
            secondaryDocumentType: {
                required,
                custom: (value) => {
                    return value !== _this.documentType
                }
            },
            sex: {
                required
            },
            birthCity: {
                required
            },
            jobFunction: {
                required
            },
            email: {
                required,
                email
            },
            phone: {
                required,
                numberValidation (value) {
                    if (!value) {
                        return false
                    }
                    const number = parsePhoneNumberFromString(`${value}`)
                    if (number && number.isValid()) {
                        return true
                    }
                    return false
                }
            },
            filteredStoredUploads: {
                custom (value) {
                    if (_this.ivanId) {
                        return true
                    }
                    const metaFields = value.map(item => item.meta)
                    const foundPrimary = this.primaryDocumentTypesOptions.find(item => item.value === this.documentType)
                    const foundSecondary = this.secondaryDocumentTypesOptions.find(item => item.value === this.secondaryDocumentType)
                    if (foundPrimary && foundPrimary.uploads.filter(item => !metaFields.includes(item.metaField)).length > 0) {
                        return false
                    }
                    if (foundSecondary && foundSecondary.uploads.filter(item => !metaFields.includes(item.metaField)).length > 0) {
                        return false
                    }
                    return true
                }
            },
            countryOrigin: {
                required
            }
        }
    }
}
</script>
<style>
    .popover.popover-large {
        max-width: 80vw;
    }
</style>
