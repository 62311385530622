<template>
    <div v-if="selectedDocumentTypeUploads && Array.isArray(selectedDocumentTypeUploads) && selectedDocumentTypeUploads.length > 0" class="bg-cyrrus-gray-light p-3">
        <uploader-row
            v-for="(upload, index) in selectedDocumentTypeUploads"
            :key="upload.metaField"
            class="bg-white p-3 card shadow-sm"
            :class="index !== (selectedDocumentTypeUploads.length - 1) ? 'mb-3' : ''"
            :title="upload.title"
            :no-documents-text="upload.noDocumentsText"
            :url="uploaderUrl"
            :uploads="uploads"
            :removed-uploads="removedUploads"
            :filtered-stored-uploads="filteredStoredUploads"
            :meta-field="upload.metaField"
            :validation="validation"
            @uploaded="onUploadUploaded"
            @remove="onUploadRemove"
            @restore="onUploadRestore"
        />
    </div>
</template>

<script>
import UploaderRow from './UploaderRow.vue'
export default {
    name: 'DocumentUploader',
    components: {
        UploaderRow
    },
    props: {
        documentType: {
            type: String,
            required: false,
            default: ''
        },
        validation: {
            type: Object,
            required: false,
            default: () => ({})
        },
        uploads: {
            type: Array,
            required: false,
            default: () => []
        },
        removedUploads: {
            type: Array,
            required: false,
            default: () => []
        },
        filteredStoredUploads: {
            type: Array,
            required: false,
            default: () => []
        },
        uploaderUrl: {
            type: String,
            required: true
        },
        options: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data () {
        return {

        }
    },
    computed: {
        selectedDocumentTypeUploads () {
            return this.options.find(poc => poc.value === this.documentType)?.uploads ?? []
        }
    },
    methods: {
        onUploadUploaded (serverData, metaField) {
            this.$emit('uploaded', serverData, metaField)
        },
        onUploadRestore (upload, metaField) {
            this.$emit('restore', upload, metaField)
        },
        onUploadRemove (upload, metaField) {
            this.$emit('remove', upload, metaField)
        }
    }
}
</script>
