<template>
    <div class="company-persons list-group bg-white shadow">
        <div class="list-group-item"
            v-for="person in people"
            :key="person._id"
        >
            <div class="row align-content-center align-items-center">
                <div class="col-12 col-sm-9 col-md-10">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="font-weight-bold text-primary">{{ $t('personList.fullname') }}</div>
                            {{ person.name }} {{ person.surname }}
                        </div>
                        <div class="col-12 col-md-3">
                            <div class="font-weight-bold text-primary">{{ $t('personList.rc') }}</div>
                            <span v-if="person.noRc === false">{{ person.rc }}</span>
                            <span v-else>{{ person.birthDate ? new Date(person.birthDate).toLocaleDateString() : '' }}</span>
                        </div>
                        <div class="col-12 col-md-5">
                            <div class="font-weight-bold text-primary">{{ $t('personList.function') }}</div>
                            {{ person.jobFunction }}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-3 col-md-2 text-sm-right whitespace-nowrap" v-if="canEdit(person)">
                    <b-btn
                        :title="$t('personList.edit')"
                        variant="primary"
                        size="sm"
                        @click.prevent="onEdit(person)"
                    >
                        <b-icon icon="pencil"></b-icon>
                    </b-btn>
                    <b-btn
                        :title="$t('personList.remove')"
                        variant="danger"
                        size="sm"
                        @click.prevent="onDelete(person)"
                        class="ml-2"
                    >
                        <b-icon icon="trash"></b-icon>
                    </b-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'person-list',
    props: {
        people: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    methods: {
        canEdit (person) {
            return this.$route.name !== 'CompanyBrokers' || !person.executive
        },
        onEdit (person) {
            if (this.canEdit(person)) {
                this.$emit('edit', person)
            }
        },
        onDelete (person) {
            if (this.canEdit(person)) {
                this.$swal.warning(
                    this.$t('personList.confirm.title'),
                    this.$t('personList.confirm.text'),
                    this.$t('personList.confirm.delete'),
                    this.$t('personList.confirm.cancel')
                ).then((value) => {
                    if (value === 'ok') {
                        this.$emit('delete', person)
                    }
                }).catch(() => { /* nothing */ })
            }
        }
    }
}
</script>

<style>
