<template>
    <div>
        <p class="h5 mb-3" v-if="title">{{ title }}</p>
        <p v-if="subTitle">{{ title }}</p>
        <div class="row">
            <div class="col-12 col-lg-6">
                <strong>{{ $t('personalId.chooseFiles')}}</strong>
                <uploader-box
                    :multi="true"
                    :url="url"
                    class="mt-3"
                    @uploaded="onUploadUploaded"
                />
            </div>
            <div class="col-12 col-lg-6">
                <strong>{{ $t('personalId.listUploadedHeading') }}</strong>
                <p
                    v-if="localFilteredStoredUploads.length === 0"
                    class="mt-3 alert"
                    :class="(validation && validation.$dirty && validation.$invalid) ? 'alert-danger' : 'alert-primary'"
                >
                    {{ noDocumentsText }}
                </p>
                <loader-overlay
                    v-if="localUploads.length > 0"
                    :loading="loadingUploads"
                    class="mt-3"
                >
                    <uploads-list
                        :uploads="localUploads"
                        :removed="removedUploads"
                        @restore="onUploadRestore"
                        @remove="onUploadRemove"
                    />
                </loader-overlay>
            </div>
        </div>
    </div>
</template>

<script>
import UploaderBox from './UploaderBox.vue'
import UploadsList from './UploadsList.vue'

export default {
    name: 'UploaderRow',
    components: {
        UploaderBox,
        UploadsList
    },
    props: {
        title: {
            title: String,
            required: false,
            default: ''
        },
        subTitle: {
            type: String,
            required: false,
            default: ''
        },
        noDocumentsText: {
            type: String,
            required: false,
            default: ''
        },
        url: {
            type: String,
            required: false,
            default: ''
        },
        uploads: {
            type: Array,
            default: () => []
        },
        removedUploads: {
            type: Array,
            default: () => []
        },
        filteredStoredUploads: {
            type: Array,
            default: () => []
        },
        loadingUploads: {
            type: Boolean,
            default: false
        },
        validation: {
            type: Object,
            required: false,
            default: undefined
        },
        metaField: {
            type: String,
            required: true
        }
    },
    computed: {
        localUploads () {
            return this.uploads.filter(item => item.meta === this.metaField)
        },
        localFilteredStoredUploads () {
            return this.filteredStoredUploads.filter(item => item.meta === this.metaField)
                .filter(item => this.localUploads.map(upload => upload._id).includes(item.upload))
        }
    },
    methods: {
        onUploadUploaded (serverData) {
            this.$emit('uploaded', serverData, this.metaField)
        },
        onUploadRestore (upload) {
            this.$emit('restore', upload, this.metaField)
        },
        onUploadRemove (upload) {
            this.$emit('remove', upload, this.metaField)
        }
    }
}
</script>
